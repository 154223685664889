/*
 *
 */
import * as React from 'react';
import Page from '../components/page';
import Button from '../components/button';

/*
 *
 */
const PageContent = () => {
	return (
		<Page
			title="La consultation de maternologie"
			intro="La maternologie est une démarche thérapeutique qui s’attache à la dimension psychique de la maternité et qui prends en compte les difficultés des relations mère-enfant."
			content={(
				<React.Fragment>
					<p>
						En effet, on estime que 10 à 15% des femmes ayant eu un enfant,
						traversent plus ou moins bien les difficultés maternelles, devenir
						mère demande un remaniement psychique qui ne va pas de soi pour
						toute les mamans.
					</p>

					<p>
						<strong>Le baby-blues</strong> est assez répandu et intervient entre 3 et 12 jours
						après la naissance, celui-ci est donc un événement transitoire qui ne
						dure que quelques jours et n’a rien de pathologique.
					</p>

					<p>
						<strong>La difficulté maternelle</strong> est bien différente et se manifeste
						généralement entre 1 mois et 3 ans après la naissance. La difficulté
						est bien plus profonde et nécessite une prise en charge spécialisée.
						Ne pas se sentir en phase avec son enfant voire ne plus rien ressentir,
						avoir des difficultés à aimer son enfant. Parler d'un accouchement
						mal vécu, se sentir épuisée, ne pas arriver à s'occuper de son bébé,
						ne plus le supporter, ne pas réussir à tout faire à la maison, se sentir
						perdue et triste, ne plus se reconnaître, ne plus arriver à dormir etc.
					</p>

					<p>
						Autant de choses qui vous font souffrir, vous culpabilise et qu'on ne
						peut confier à personne, même pas, parfois, à son conjoint.
						Je vous propose une écoute bienveillante et spécialisée qui vous
						permettra de traverser ce moment difficile.
					</p>

					<br />
					<Button to="/rendez-vous/" label="Prendre un rendez-vous" />

				</React.Fragment>
			)}
		>
			<title>La consultation de maternologie — Isabelle Kopp</title>
		</Page>
	);
};

export default PageContent;
